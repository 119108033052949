import React from "react";
import {StackLayout} from "@progress/kendo-react-layout";


export const AlreadyPlayedScreen = () => {
    return (
        <>
            <img className={'logo'} src={"./images/geard_logo.svg"} alt={"gEARED!!"}/>

            <StackLayout orientation={"vertical"}>
                <div className={'plate'}>
                    <img className={'logo-plate'} src={"./images/geard_logo_plate.svg"} alt={"gEARED!!"}/>
                    <h1>C U L8TR</h1>
                </div>
                <div className={'introText k-flex-30'}>
                    <p>Whoa, love the enthusiasm &mdash; but you've already played today</p>
                    <p>Come back tomorrow!</p>
                </div>
            </StackLayout>
        </>
    );
};