export class CarInfo {
    questionNumber?: number;
    soundFile?: string;
    option1?: string;
    option2?: string;
    option3?: string;
    option4?: string;
    ans?: number;
    factoid?: string;

    constructor(questionNumber?: number, soundFile?: string, option1?: string, option2?: string, option3?: string, option4?: string, ans?: number, factoid?: string) {
        this.questionNumber = questionNumber;
        this.soundFile = soundFile;
        this.option1 = option1;
        this.option2 = option2;
        this.option3 = option3;
        this.option4 = option4;
        this.ans = ans;
        this.factoid = factoid;
    }
}