import React, {useState} from "react";
import {StackLayout} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";


// @ts-ignore
export const WelcomeScreen = ({startPlaying, buttonClick}) => {
    const [showTimer, setShowTimer] = useState(false);
    const [timerText, setTimerText] = useState('3');
    const [timerClassName, setTimerClassName] = useState('timer-badge border-green');
    const handleStart = () => {
        buttonClick(startPlaying);
    }
    return (
        <>
            <img className={'logo'} src={"./images/geard_logo.svg"} alt={"gEARED!!"}/>
            {showTimer ?
            <>
                <h1 className={timerClassName}>{timerText}</h1>
            </> :
                <StackLayout orientation={"vertical"}>
                    <div className={'beta-message k-flex-10'}><h2>BETA</h2></div>
                    <div className={'k-flex-20'} style={{marginTop: ".5rem"}}>
                        <Button themeColor={'primary'} className={'start-button'} style={{fontSize: "xx-large"}}
                                onClick={handleStart}>GO!</Button>
                    </div>
                    <div className={'introText k-flex-30'}>
                        <p>gEAR’D!! is all about the sounds so please turn your VOLUME UP!</p>
                    </div>
                </StackLayout>
            }
        </>
    );
};