import React, {useEffect, useMemo, useRef, useState} from "react";
import {data} from "../assets/quiz-data";
import {Factoid} from "./Factoid";
import {ReactComponent as Gears} from "../assets/gears.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare} from "@fortawesome/free-solid-svg-icons"
import {Canvg} from "canvg";
import {Button} from "@progress/kendo-react-buttons";
import {Slide} from "@progress/kendo-react-animation";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {AppBar, AppBarSection} from "@progress/kendo-react-layout";
import {AudioMotionSoundVisualizer} from "./AudioMotionSoundVisualizer";
import {SvgIcon} from "@progress/kendo-react-common";
import {playIcon} from "@progress/kendo-svg-icons";
import {CarInfo} from "../models/CarInfo";
import {PlayerHistory} from "../models/PlayerHistory";

// @ts-ignore
export const GameScreen = ({buttonClick}) => {
    const [index, setIndex] = useState(0);
    const [car, setCar] = useState<CarInfo>(new CarInfo());
    const [lock, setLock] = useState(false);
    const [score, setScore] = useState(0);
    const [scoreBoard, setScoreBoard] = useState(new Array(data.length));
    const [showResult, setShowResult] = useState(false);
    const [showSound, setShowSound] = useState(true);
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [canUseClipboard, setCanUseClipboard] = useState(false);
    const [gameUrl] = useState(document.location.href);
    const [showShareCancelled, setShowShareCancelled] = useState(false);
    const [showClipboardCopied, setShowClipboardCopied] = useState(false);

    const [daysInARow, setDaysInARow] = useState(1);
    const [lapsToday, setLapsToday] = useState(1);

    const Option1: React.MutableRefObject<HTMLLIElement | null> = useRef(null);
    const Option2: React.MutableRefObject<HTMLLIElement | null> = useRef(null);
    const Option3: React.MutableRefObject<HTMLLIElement | null> = useRef(null);
    const Option4: React.MutableRefObject<HTMLLIElement | null> = useRef(null);
    const options: React.MutableRefObject<HTMLLIElement | null>[] = [Option1, Option2, Option3, Option4];

    const gearSelectors = useMemo(() => ['first_gear', 'second_gear', 'third_gear', 'fourth_gear', 'fifth_gear', 'sixth_gear'], []);
    const gearSvg = useRef<SVGSVGElement | null>(null);
    const clipboardSvg = useRef<HTMLImageElement | null>(null);

    const extraCanvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        setShowSound(true);
        const now = new Date();
        const dateString = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
        const theQuiz = data.find(t => t.date === dateString);
        const theQuestion = theQuiz?.questions[index];
        // @ts-ignore
        setCar(theQuestion);
        setLock(false);
    }, [index]);

    useEffect(() => {
        const queryOpts = {name: 'clipboard-write' as PermissionName, allowWithoutGesture: false};
        try {
            navigator.permissions.query(queryOpts)
                .then((status) => {
                    setCanUseClipboard(status.state === 'granted');
                    status.onchange = () => {
                        setCanUseClipboard(status.state === 'granted');
                    }
                })
                .catch((reason) => console.log('reject reason', reason));
        } catch (e) {
            console.log('error getting clipboard permissions.', e);
        }
    }, []);

    useEffect(() => {
        if (showResult) {
            scoreBoard.forEach((value, index) => {
                const gearId = gearSelectors[index];
                const gear = document.querySelector(`#${gearId}`);
                gear?.classList.remove('correct', 'wrong');
                gear?.classList.add(value ? 'correct' : 'wrong');
            });
        }
    }, [showResult, scoreBoard, gearSelectors]);

    // @ts-ignore
    const checkAnswer = (e, ans) => {
        if (!lock) {
            setLock(true);
            const gearId = gearSelectors[index];
            const gear = document.querySelector(`#${gearId}`);
            // @ts-ignore
            if (car.ans === ans) {
                e.target.classList.add('correct');
                gear?.classList.add('correct');
                setScore((prev) => ++prev);
            } else {
                e.target.classList.add('wrong');
                gear?.classList.add('wrong');
                // @ts-ignore
                options[car.ans - 1].current.classList.add('correct');
            }
            setScoreBoard((prev) => {
                const newScoreBoard = [...prev];
                // @ts-ignore
                newScoreBoard[index] = car.ans === ans;
                return newScoreBoard;
            });

            setTimer(setTimeout(() => setShowSound(false), 800));
        }
    }

    const clickNext = () => {
        buttonClick(next);
    }

    function dateAgo(daysBefore: number) {
        const someDate = new Date();
        someDate.setDate(someDate.getDate() - daysBefore);
        return `${someDate.getFullYear()}/${someDate.getMonth() + 1}/${someDate.getDate()}`;
    }

    const next = () => {
        if (lock) {
            if (timer) {
                clearTimeout(timer);
                setTimer(undefined);
            }
            const now = new Date();
            const dateString = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
            const theQuiz = data.find(t => t.date === dateString);
            const questionCount = theQuiz?.questions.length
            // @ts-ignore
            if (index === questionCount - 1) {
                setShowResult(true);
                const historyInStorage = localStorage.getItem("history");
                let history: PlayerHistory | null = null;
                let newVersion = false;
                if (historyInStorage != null) {
                    history = JSON.parse(historyInStorage);
                    newVersion = true;
                } else {
                    history = new PlayerHistory();
                }
                // laps today
                let previousLaps = 0;
                if (history?.latestDate && history?.latestDate === dateString) {
                    previousLaps = history!.lapsToday!;
                }
                history!.lapsToday = previousLaps + 1;

                // days in a row
                let calcDaysInARow = 0;
                const yesterdayString = dateAgo(1);
                if (newVersion) {
                    if (history?.latestDate && history?.latestDate === yesterdayString) {
                        calcDaysInARow = history!.daysInARow! + 1;
                    } else if (history?.latestDate && history?.latestDate === dateString) {
                        calcDaysInARow = history!.daysInARow!;
                    } else {
                        calcDaysInARow = 1;
                    }
                } else {
                    for (let i = 1; i < 50; i++) {
                        calcDaysInARow = i;
                        const searchString = dateAgo(i);
                        if (localStorage.getItem(searchString) == null) {
                            break;
                        }
                    }
                }

                history!.daysInARow = calcDaysInARow;
                history!.latestDate = dateString;

                // todo: create state variables for laps and streaks
                setLapsToday(history!.lapsToday!);
                setDaysInARow(calcDaysInARow);

                localStorage.setItem("history", JSON.stringify(history));
                return 0;
            }
            setIndex((prev) => ++prev);
            options.map((option) => {
                option.current?.classList.remove('correct');
                option.current?.classList.remove('wrong');
                return null;
            });
        }
    }

    const resultMessage = (): string => {
        const messages = new Map<number, string>([
            [0, "Do you actually own a vehicle?"],
            [1, "Maybe you should switch to a bike?"],
            [2, "Not bad... for a Tesla owner."],
            [3, "50%... respectable."],
            [4, "A perfect score is in your reach. Keep trying... we believe in you!"],
            [5, "TOTALLY worth bragging to your friends about. Nice!"],
            [6, "You 100% have grease in your veins. Well done!"]
        ]);

        return messages.get(score) ?? "Did you check your volume?";
    }

    const share = async () => {
        const svg = gearSvg.current!;
        let svgString = new XMLSerializer().serializeToString(svg);
        scoreBoard.forEach((value, index) => {
            const gearId = gearSelectors[index];
            svgString = svgString
                .replace(
                    `id="${gearId}" fill="#ddd"`,
                    `id="${gearId}" fill="${value ? 'lawngreen' : 'red'}"`);
        });
        const canvas = extraCanvasRef.current!;
        // @ts-ignore
        const context = canvas.getContext('2d');
        // @ts-ignore
        const v = Canvg.fromString(context, svgString);
        await v.render();

        const dataUrl = canvas.toDataURL();
        if (clipboardSvg.current) {
            clipboardSvg.current.src = dataUrl;
        }
        const blob = await (await fetch(dataUrl)).blob();

        const shareMessage = `gEAR'D!! ${score}/6 Lap #${lapsToday}\nPlay gEAR'D!! today @ ${document.location.href}\n`;
        if (navigator.canShare && navigator.canShare({text: "message"})) {
            const filesArray = [
                new File(
                    [blob],
                    'geared.png',
                    {
                        type: blob.type,
                        lastModified: new Date().getTime()
                    }
                )
            ];
            const shareData = {
                text: shareMessage,
                files: filesArray,
            };
            try {
                await navigator.share(shareData);
            } catch (e) {
                // @ts-ignore
                if (e.toString().includes('Abort')) {
                    setShowShareCancelled(true);
                    setTimeout(() => setShowShareCancelled(false), 2000);
                }
            }
        } else if (canUseClipboard) {
            const toCopy = document.querySelector('#hidden-results');
            await navigator.clipboard.write([
                new ClipboardItem({
                    "text/html": new Blob([toCopy!.innerHTML], {type: "text/html"})
                })
            ]);
            setShowClipboardCopied(true);
            setTimeout(() => setShowClipboardCopied(false), 2000);
        }
    }

    // @ts-ignore
    return (
        <div className={'container flex-wrapper'}>
            {showResult ? <>
                <div className={'k-flex-30'} style={{marginTop: '2rem'}}>
                    <Gears ref={gearSvg} className='gears-result' id='result-gear-image'/>
                    <h2>You scored {score} out of 6</h2>
                    <p className={'fun-message'}>{resultMessage()}</p>
                    <p className={'streaks'}>{lapsToday} {lapsToday == 1 ? 'lap' : 'laps'} today<br/>{daysInARow} day streak!!</p>
                    {
                        (canUseClipboard || (navigator.canShare && navigator.canShare({text: "message"}))) &&
                        <Button themeColor={"inverse"} className={'share-button'} onClick={share}>SHARE <FontAwesomeIcon
                            className={'share-icon'}
                            icon={faShare}/></Button>
                    }
                </div>
                <div id={'hidden-results'}>
                    <h3>gEAR'D!! {score}/6</h3>
                    <p>Play gEAR'D!! today @<a href={gameUrl}>geared.io</a></p>
                    <img height={200} width={200} ref={clipboardSvg} src={`${gameUrl}/images/gears.svg`} alt={'score'}/>
                </div>
                <NotificationGroup
                    style={{
                        right: 5,
                        bottom: 5,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }}
                >
                    <Slide direction={showShareCancelled ? "up" : "down"}>
                        {showShareCancelled && (
                            <>
                                <Notification
                                    type={{
                                        style: "info",
                                        icon: true,
                                    }}
                                >
                                    Share cancelled
                                </Notification>
                            </>
                        )}
                    </Slide>
                    <Slide direction={showClipboardCopied ? "up" : "down"}>
                        {showClipboardCopied && (
                            <>
                                <Notification
                                    type={{
                                        style: "success",
                                        icon: true,
                                    }}
                                >
                                    Results are copied to the clipboard
                                </Notification>
                            </>
                        )}
                    </Slide>
                </NotificationGroup>
                <canvas className={'hidden'} ref={extraCanvasRef} height={'320px'} width={'320px'}/>
            </> : <>
                <ul>
                    <li ref={Option1} onClick={(e) => checkAnswer(e, 1)}>{car.option1}</li>
                    <li ref={Option2} onClick={(e) => checkAnswer(e, 2)}>{car.option2}</li>
                    <li ref={Option3} onClick={(e) => checkAnswer(e, 3)}>{car.option3}</li>
                    <li ref={Option4} onClick={(e) => checkAnswer(e, 4)}>{car.option4}</li>
                </ul>
                <div>
                    {showSound ?
                        <AudioMotionSoundVisualizer soundFile={car.soundFile} soundIndex={index}/> :
                        <>
                            <Factoid text={car.factoid}/>
                            <Button disabled={!lock} className={'next-button'}
                                    onClick={clickNext}>NEXT&nbsp;<SvgIcon size={"xlarge"} icon={playIcon}/></Button>
                        </>
                    }
                </div>
                {/*<div>&nbsp;</div>*/}
                <AppBar position={'bottom'} style={{backgroundColor: 'inherit'}}>
                    <AppBarSection style={{width: '30%', float: 'left'}}>
                        <Gears className='gears'/>
                    </AppBarSection>
                    <AppBarSection style={{width: '65%', float: 'right'}}>
                        <img className={'footer-logo'} src={"./images/geard_logo.svg"} alt={"gEARED!!"}/>
                    </AppBarSection>
                </AppBar>
            </>}
        </div>
    )
};