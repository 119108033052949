import React, {useEffect, useRef, useState} from 'react';
import './App.scss';
import {WelcomeScreen} from "./Components/WelcomeScreen";
import {GameScreen} from "./Components/GameScreen";
import {AlreadyPlayedScreen} from "./Components/AlreadyPlayedScreen";

function App() {
    const [gameStage, setGameStage] = useState('welcome');
    const buttonClickAudioRef = useRef<HTMLAudioElement | null>(null);

    // useEffect(() => {
    //     const now = new Date();
    //     const dateString = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
    //     const playedToday = localStorage.getItem(dateString);
    //     if (playedToday === 'true') {
    //         setGameStage('already-played');
    //     }
    // }, []);

    const startPlaying = () => {
        setGameStage('playing');
    }

    const buttonClick = (requestedAction: () => void) => {
        if (buttonClickAudioRef.current) {
            buttonClickAudioRef.current.play().then(() => {
                setTimeout(() => {
                    requestedAction();
                }, 300);
            });
        }
    }

    return (
        <div className="App">
            <audio preload={'auto'} ref={buttonClickAudioRef} src={'/sounds/double-click-metallic-SBA-300130466.mp3'}/>
            {(gameStage === 'already-played') ? <AlreadyPlayedScreen/> : (gameStage === 'welcome') ? <WelcomeScreen startPlaying={startPlaying} buttonClick={buttonClick} /> :
                <GameScreen buttonClick={buttonClick}/>
            }
        </div>
    )
}

export default App;
