import React, {useEffect, useRef, useState} from "react";
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import {arrowRotateCcwIcon} from "@progress/kendo-svg-icons";
import {SvgIcon} from "@progress/kendo-react-common";

// @ts-ignore
export const AudioMotionSoundVisualizer = ({soundFile, soundIndex}) => {
    const [audioPlaying, setAudioPlaying] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const audioMotionRef = useRef<AudioMotionAnalyzer | null>(null);
    const [showReplay, setShowReplay] = useState(false);

    useEffect(() => {
        if (audioPlaying && audioRef.current) {
            const audio = audioRef.current;
            audio.play().then(r => setShowReplay(true));
        }
    }, [audioPlaying]);

    useEffect(() => {
        setAudioPlaying(soundIndex > 0);
        if (audioRef.current) {// && canvasContextRef.current && canvasRef.current) {
            const audio = audioRef.current;

            if (containerRef.current && !audioMotionRef.current) {
                audioMotionRef.current =
                    new AudioMotionAnalyzer(
                        containerRef.current,
                        {
                            source: audio,
                            "alphaBars": false,
                            "ansiBands": true,
                            "barSpace": 0.5,
                            "bgAlpha": 0.7,
                            "channelLayout": "single",
                            "colorMode": "gradient",
                            "fadePeaks": false,
                            "fftSize": 8192,
                            "fillAlpha": 1,
                            "frequencyScale": "log",
                            "gradient": "classic",
                            "gravity": 3.8,
                            "ledBars": true,
                            "linearAmplitude": false,
                            "linearBoost": 1,
                            "lineWidth": 0,
                            "loRes": false,
                            "lumiBars": false,
                            "maxDecibels": -25,
                            "maxFPS": 0,
                            "maxFreq": 16000,
                            "minDecibels": -85,
                            "minFreq": 25,
                            "mirror": 0,
                            "mode": 8,
                            "noteLabels": false,
                            "outlineBars": true,
                            "overlay": false,
                            "peakFadeTime": 750,
                            "peakHoldTime": 500,
                            "peakLine": true,
                            "radial": false,
                            "radialInvert": false,
                            "radius": 0.3,
                            "reflexAlpha": 0.15,
                            "reflexBright": 1,
                            "reflexFit": true,
                            "reflexRatio": 0,
                            "roundBars": false,
                            "showBgColor": true,
                            "showFPS": false,
                            "showPeaks": true,
                            "showScaleX": false,
                            "showScaleY": false,
                            "smoothing": 0.5,
                            "spinSpeed": 0,
                            "splitGradient": false,
                            "trueLeds": true,
                            "useCanvas": true,
                            "volume": 1,
                            "weightingFilter": ""
                        });
            }

            audio.addEventListener('ended', () => {
                setAudioPlaying(false);
            });
        }
    }, []);

    const onStart = () => {
        setAudioPlaying(true);
    };

    return (
        <div className={'visualizer '} onClick={onStart}>
            <audio ref={audioRef} src={`/sounds/${soundFile}`}/>
            {!audioPlaying &&
                <>
                {showReplay ?
                    <button className={"replay-button"} onClick={onStart}>&nbsp;<br/>REPLAY?</button> :
                    <button className={"start-button"} onClick={onStart}>PUSH<br/>TO START</button>
                }
                </>
            }
                <div id={"vis"} ref={containerRef} hidden={!audioPlaying}>
                </div>
</div>
)
    ;
};